import { useNavigate } from "react-router-dom";
import { Radar } from "react-chartjs-2";
import {
  Chart,
  RadialLinearScale,
  RadarController,
  PolarAreaController,
  PointElement,
  LineElement,
} from "chart.js";
import { useResize } from "../../Hooks/useResize";
import "./Result.scss";
import presentImage from "../../Assets/Images/present_image.png";
import logo from "../../Assets/Images/ЛОГОТИП.png";
import bot from "../../Assets/Images/bot.png";
import { useState } from "react";

Chart.register(
  RadialLinearScale,
  RadarController,
  PolarAreaController,
  PointElement,
  LineElement
);

const Result = () => {
  const navigate = useNavigate();
  const emptyChart = ["", "", "", "", "", "", "", "", "", "", ""];
  const chartData = JSON.parse(localStorage.getItem("result_data"));
  const [is_popUp, setIsPopUP] = useState(false);
  const tokenData = JSON.parse(localStorage.getItem("token_data"));
  const size = useResize();

  const labelInfo = [
    "Блок 1",
    "Блок 2",
    "Блок 3",
    "Блок 4",
    "Блок 5",
    "Блок 6",
    "Блок 7",
    "Блок 8",
    "Блок 9",
    "Блок 10",
    "Блок 11",
  ];

  const chartBackground = [
    "#8ebfff",
    "#00A6B4",
    "#50c6ca",
    "#4d7fbe",
    "#3b71b86e",
    "#004094",
    "#390094",
  ];

  const chartHover = [
    "#3b71b88f",
    "#00a5b47a",
    "#50c6ca8c",
    "#4d80be80",
    "#3b71b82d",
    "#00409475",
    "3900946b",
    "b002db6b",
  ];

  const width = useResize();

  const options = {
    scales: {
      r: {
        pointLabels: {
          display: false,
        },
      },
    },
  };

  const radarChartData = {
    labels: labelInfo,
    datasets: [
      {
        backgroundColor: chartBackground,
        borderColor: "#8ebfff",
        fill: false,
        hoverBackgroundColor: chartHover,
        data: chartData ? Object.values(chartData.collect) : emptyChart,
      },
    ],
  };

  return (
    <div className="WrapperResult">
      <div className="resultHeader">
        {size.width > 1024 ? (
          <h1>Спасибо за ваше время и поддержку!</h1>
        ) : (
          <h2>Спасибо за ваше время и поддержку!</h2>
        )}
        {/* <div className="botBox">
          <div onClick={() => window.open("https://telegram.me/boxtestQA_bot")}>
            Задать вопрос
          </div>
          <img
            className="botLogo"
            alt=""
            src={bot}
            onClick={() => window.open("https://telegram.me/boxtestQA_bot")}
          ></img>
          <img alt="" src={logo}></img>
        </div> */}
      </div>
      <div className="ResultBox">
        <div className="ResultContent">
          <div>
            <div>

              Спасибо, что нашли время  пройти на наш опрос! Ваше мнение для нас очень важно, и мы рады, что вы разделили с нами свои мысли.
            </div>

          </div>
          <div className="ButtonBox">
            {/* <div className="DownloadButton" onClick={getPDF}>
              Сохранить результат
            </div> */}
            <div
              className="NextButton"
              onClick={() => {
                window.scrollTo(0, 0);
                setIsPopUP(true);
              }}
            >
              Завершить
            </div>
          </div>
        </div>
      </div>

      <div className="AgainBox">
        <div className="AgainButton" onClick={() => navigate("/TokenData")}>
          Попробовать ещё раз
        </div>
        {/* <div className="resultMapBox">
          <div className="stepBox">
            <div>шаг</div>
            <div className="currentStep">1</div>
          </div>
          <div className="stepLine"></div>
          <div className="stepBox">
            <div>шаг</div>
            <div className="currentStep">2</div>
          </div>
          <div className="stepLine"></div>
          <div className="stepBox">
            <div>шаг</div>
            <div className="currentStep">3</div>
          </div>
          <div className="stepLine"></div>
          <div className="stepBox">
            <div>шаг</div>
            <div className="currentStep">4</div>
          </div>
          <div className="stepLine"></div>
          <div>
            <img src={presentImage} alt=""></img>
          </div>
        </div> */}
      </div>
      {is_popUp && (
        <>
          <div className="WrapperPopUp">
            <div
              className="close"
              onClick={() => {
                setIsPopUP(false);
                document.body.style.overflowY = "auto";
                // navigate("/");
              }}
            ></div>
            <h1>Благодарим Вас за участие!</h1>
            {/* <div className="userID">
              <h1>{tokenData.user_id}</h1>
            </div>
            <label>
              Это Ваш личный уникальный номер участника опроса.
            </label> */}

          </div>
          <div
            className="ScreenBlack"
            onClick={() => {
              setIsPopUP(false);
              document.body.style.overflowY = "auto";
            }}
          ></div>
        </>
      )}
    </div>
  );
};

export default Result;
